import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import { Controller, useFormContext } from 'react-hook-form';
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import get from 'lodash/get';

function ListBoxSelect({ id, name, options, defaultValue, placeholder, rules, disabled, className }) {
  const { control, formState: { isSubmitting, errors } } = useFormContext();

  const inputError = get(errors, name);

  return (
    <div className="relative mt-1">
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        rules={rules}
        render={({
          field: { onChange, value },
        }) => {
          const selectedOption = options.find(option => option.value === value);

          return (
            <Listbox name={name} value={value} onChange={onChange} disabled={isSubmitting}>
              {({ open }) => (
                <>
                  <Listbox.Button
                    className={clsx(
                      'border rounded-md',
                      inputError
                        ? 'border-orange-O500 text-orange-O500 focus:border-orange-O500 focus:outline-none focus:ring-orange-O500/50'
                        : 'border-brown-B500 focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60',
                      'relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm',
                    )}
                  >
                    <span className="block truncate">{selectedOption ? selectedOption.label : placeholder}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      {options.map((option) => (
                        <Listbox.Option
                          key={option.value}
                          className={({ active }) => clsx(
                            active ? 'bg-yellow-Y50' : ' ',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )}
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={clsx(
                                  selected
                                    ? 'font-semibold'
                                    : 'font-normal', 'block truncate'
                                )}>
                                {option.label}
                              </span>

                              {selected && (
                                <span
                                  className={clsx(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </>
              )}
            </Listbox>
          );
        }}
      />

      {inputError && (
        <Error>
          {inputError.message}
        </Error>
      )}
    </div>
  );
}

ListBoxSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })),
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

ListBoxSelect.defaultProps = {
  defaultValue: null,
  options: [],
  placeholder: '',
  rules: {},
  disabled: false,
};

export default ListBoxSelect;
