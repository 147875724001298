import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";

function Radio({ id, name, value, rules, children, className }) {
  const { register } = useFormContext();

  return (
    <div className="flex items-center">
      <input
        {...register(name, { ...rules })}
        type="radio"
        id={id}
        name={name}
        value={value}
        className={clsx(
          'h-4 w-4 border-gray-300 text-yellow-Y100 focus:ring-yellow-Y60',
          className
        )}
      />

      <label htmlFor={id} className="ml-3 block text-sm font-medium text-gray-700">
        {children}
      </label>
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rules: PropTypes.object,
};

Radio.defaultProps = {
  rules: {},
};

export default Radio;
