import React from 'react';
import { MainHeadingSection, MainText, MainTitle } from "../../../shared/components/layout";
import { useLoaderData, Form, useNavigation, useActionData, redirectDocument } from "react-router-dom";
import axios from "axios";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import illuRDV from "../../../assets/img/illu-check-rdv-w.png";
import logoTeam from "../../../assets/img/logo-izika-team.png";

export async function loader({ params }) {
  try {
    const response = await axios.get(
      `${window.ENV.API_URL}/security/employee-invitation/${params.token}`
    );

    return {
      token: params.token,
      invitation: response.data,
    }
  } catch(error) {
    throw new Response(error.message, { status: error.response.status });
  }
}

export async function action({ request }) {
  const formData = await request.formData();
  const password = formData.get("password");
  const token = formData.get("token");
  const errors = {};

  if (typeof password !== "string" || password.length === 0) {
    errors.password = "Le mot de passe est obligatoire";
  } else if (password.length < 8) {
    errors.password = "Le mot de passe doit contenir au moins 8 caractères";
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  await axios.post(
    `${window.ENV.API_URL}/security/employee-invitation/${token}`,
    {
      password,
    }
  );

  return redirectDocument('/');
}

export default function ConfirmInvitation() {
  const { token, invitation } = useLoaderData();
  const errors = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <div className="flex min-h-full flex-1">

        <div className="flex">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">

              <MainHeadingSection>
                <img src={logoTeam} alt="logo izika team" className="w-24" />
                <MainTitle>Rejoignez {invitation.enterprise.name}</MainTitle>
                <MainText>
                  Vous avez été invité à rejoindre {invitation.enterprise.name} sur izika.
                  Saisissez un mot de passe pour votre compte et validez pour confirmer votre invitation.
                  <br/>
                  <br/>
                  Votre email de connexion est : <br/>
                  <strong>{invitation.user.email}</strong>
                </MainText>
              </MainHeadingSection>

              <Form method="post" noValidate="novalidate" className="space-y-6">
                <div>
                  <label htmlFor="password" className="block text-sm font-medium">
                    Mot de passe
                  </label>
                  <div className="mt-1">
                    <input type="hidden" name="token" value={token} />
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="Mot de passe"
                      disabled={isSubmitting}
                      required
                      className="block w-full appearance-none rounded-md border border-brown-B500 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60 sm:text-sm"
                    />
                    {errors?.password && (
                      <div className="text-sm text-orange-O500">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <SubmitButton loading={isSubmitting}>
                    Rejoindre {invitation.enterprise.name}<FontAwesomeIcon fixedWidth icon={faChevronRight}/>
                  </SubmitButton>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div className="relative hidden w-0 flex-1 lg:block bg-gradient-to-tr from-yellow-Y50 to-yellow-Y40">
          <img
            className="absolute inset-0 h-full w-full object-contain max-w-lg bg-center mx-auto"
            src={illuRDV}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
