import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import clsx from "clsx";
import get from 'lodash/get';
import { useFormContext } from "react-hook-form";

const classNames = {
  base: 'w-full appearance-none border px-3 py-2 placeholder-neutrals-N50 shadow-sm sm:text-sm',
  state: {
    neutral: 'border-brown-B500 focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60',
    error: 'border-orange-O500 text-orange-O500 placeholder-orange-O500 focus:border-orange-O500 focus:outline-none focus:ring-orange-O500/50',
  },
  variant: {
    default: 'block rounded-md',
    group: 'min-w-0 flex-1 rounded-none',
  }
}

function Input({ id, name, type, placeholder, rules, disabled, showErrors, variant, className }) {
  const { register, formState: { isSubmitting, errors } } = useFormContext();
  const inputError = get(errors, name);

  const InputComponent = (
    <>
      <input
        {...register(name, { ...rules })}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        disabled={isSubmitting || disabled}
        className={clsx(
          classNames.base,
          classNames.variant[variant],
          classNames.state[inputError ? 'error' : 'neutral'],
          className
        )}
      />

      {(showErrors && inputError) && (
        <Error>
          {inputError.message}
        </Error>
      )}
    </>
  );

  if ('default' === variant) {
    return (
      <div className="mt-1">
        {InputComponent}
      </div>
    );
  }

  return InputComponent;
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text']),
  variant: PropTypes.oneOf(['default', 'group']),
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  showErrors: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  variant: 'default',
  placeholder: '',
  rules: {},
  disabled: false,
  showErrors: true,
};

export default Input;
