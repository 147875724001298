import React from 'react';
import { gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next/icu.macro";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { MainContainer, MainHeadingSection, MainText, MainTitle } from "../../../shared/components/layout";
import { Form, Label, EmailMultiSelect } from "../../../shared/components/form";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useSubmitTeamOnboarding } from "../useCases/useSubmitTeamOnboarding";
import { ensureUserIsAuthenticatedAndOnboardingIsNotCompleted } from "../Team.page";

Invite.fragments = {
  onboarding: gql`
    fragment Invite on TeamOnboarding {
      companyName
      country
      fleetSize
      usage
      firstName
      lastName
      phoneNumber
      accountingClosureMonth
      invitations
      customRates {
        label
        index
        bonus
        currency
      }
    }
  `,
};

export async function loader(args, context) {
  await ensureUserIsAuthenticatedAndOnboardingIsNotCompleted(context);

  const { data } = await context.apolloClient.query({
    query: gql`
      query GetOnboardingInvite {
        onboarding {
          memberId
          ... on TeamOnboarding {
            ...Invite
          }
        }
      }
      ${Invite.fragments.onboarding}
    `,
  });

  return {
    data: data.onboarding,
  };
}

function getValidationSchema(t) {
  return yup.object({
    invitations: yup
      .array()
      .of(
        yup
          .string().typeError(t('team_invite_invitations_error'))
          .email(t('team_invite_invitations_error'))
      ).notRequired()
  });
}


export default function Invite() {
  const { t } = useTranslation();
  const { data } = useLoaderData();
  const navigate = useNavigate();
  const { submitTeamOnboarding } = useSubmitTeamOnboarding();

  const methods = useForm({
    resolver: yupResolver(getValidationSchema(t)),
    defaultValues: {
      invitations: data.invitations ?? [],
    },
  });
  const { formState: { isSubmitting } } = methods;

  const onSubmit = async (values) => {
    await submitTeamOnboarding(
      data.companyName,
      data.country,
      data.fleetSize,
      data.usage,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      data.accountingClosureMonth,
      data.customRates?.map(({ label, index, bonus, currency }) => ({ label, index, bonus, currency })) || null,
      values.invitations,
      true,
    );

    navigate("/join/team/what-now");
  };

  return (
    <MainContainer>
      <MainHeadingSection>
        <MainTitle>Invitez vos collaborateurs</MainTitle>
        <MainText>
          Ajoutez des utilisateurs à Izika en renseignant leurs adresses e-mail :<br/>
          <small>(Vous pouvez le faire plus tard)</small>
        </MainText>
      </MainHeadingSection>

      <Form methods={methods} onSubmit={onSubmit}>
        <div className="space-y-5">
          <div>
            <Label htmlFor="invitations">
              <Trans i18nKey="team_invite_invitations_label">
                Invitez les emails:
              </Trans>
            </Label>

            <EmailMultiSelect
              id="invitations"
              name="invitations"
              placeholder={t('team_invite_invitations_placeholder', 'name@entreprise.com')}
            />
          </div>

          <SubmitButton loading={isSubmitting} className="mt-6">
            {isSubmitting ? (
              <Trans i18nKey="team_invite_loading">
                Chargement
              </Trans>
            ) : (
              <Trans i18nKey="team_invite_submit">
                Continuer
              </Trans>
            )}
          </SubmitButton>
        </div>
      </Form>
    </MainContainer>
  );
}
