import { useState } from "react";
import axios from "axios";
import { markAuthenticated } from "./useAuthenticate";
import { useApolloClient } from "@apollo/client";

export function useSignUp() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const client = useApolloClient();

  const signUp = async (
    email,
    password,
    product,
  ) => {
    setLoading(true);

    let endpoint = `${window.ENV.API_URL}/security/sign-up`;

    try {
      const response = await axios.post(endpoint, {
        username: email,
        password,
        product,
      }, {
        withCredentials: true,
      });

      await client.resetStore();

      markAuthenticated();
      setData(response.data?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    data,
    error,
    signUp,
  };
}
