import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import range from 'lodash/range';

function createOptions() {
  const fromMonth = moment().month(0);
  const toMonth = moment().month(11);

  return range(fromMonth.month(), toMonth.month() + 1).map(monthNumber => {
    const month = moment().month(monthNumber);

    return {
      label: month.format('MMMM'),
      value: (monthNumber + 1).toString(),
    }
  });
}

function MonthSelect({ id, onChange, onBlur, disabled, value, placeholder }) {
  const options = useMemo(() => createOptions(), []);

  const getValue = () => {
    if (null === value || '' === value) {
      return null;
    }

    return options.find(option => option.value === value);
  }

  return (
    <Select
      id={id}
      closeOnSelect={true}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={false}
      hideSelectedOptions={false}
      options={options}
      placeholder={placeholder}
      value={getValue()}
      onChange={onChange}
      onBlur={onBlur}
      selectProps={{autoComplete: 0}}
    />
  );
}

MonthSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

MonthSelect.defaultProps = {
  disabled: false,
  value: null,
  placeholder: 'Select a month',
};

export default MonthSelect;
