import React from 'react';
import PropTypes from "prop-types";
import { FormProvider } from "react-hook-form";
import clsx from "clsx";

function Form({ methods, onSubmit, className, children }) {
  return (
    <FormProvider {...methods}>
      <form
        className={clsx(
          'space-y-6 divide-y divide-brown-B500',
          className
        )}
        noValidate="novalidate"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
}

Form.propTypes = {
  methods: PropTypes.object.isRequired, // react-hook-form useForm result
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
