import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import get from 'lodash/get';
import { Controller, useFormContext } from "react-hook-form";
import EmailMultiSelectComponent from "../EmailMultiSelect";

function EmailMultiSelect({ id, name, placeholder, rules, disabled, className }) {
  const { control, formState: { errors } } = useFormContext();
  const inputError = get(errors, name);

  return (
    <div className="mt-1">
      <Controller
        render={({
          field: { onChange, onBlur, value },
        }) => (
          <EmailMultiSelectComponent
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        name={name}
        control={control}
        rules={rules}

      />

      {inputError && (
        <Error>
          {inputError.message}
        </Error>
      )}
    </div>
  );
}

EmailMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

EmailMultiSelect.defaultProps = {
  placeholder: 'Enter email addresses',
  rules: {},
  disabled: false,
};

export default EmailMultiSelect;
