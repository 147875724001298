import { ApolloClient, from, createHttpLink } from "@apollo/client";
import { apolloCache } from "./apollo-cache";
import { onError } from "@apollo/client/link/error";

export default function initApolloClient(authToken = null) {
  const httpLink = createHttpLink({
    uri: window.ENV.GRAPHQL_SERVER_URL,
    credentials: 'development' === process.env.NODE_ENV ? 'include' : 'same-origin',
  });

  const reportErrors = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      const { response } = networkError;
      if (response.redirected && response.url.endsWith('/sign-in')) {
        // on a reçu un redirect vers le sign-in du serveur
        // on vide l'auth du state
        // gros hack pour les cas où on peut pas exécuter de query GraphQL parce qu'on est pas loggué
        // ce hack sert à catch l'erreur proprement dans React Router
        networkError.message = 'unauthorized';
      } else {
        console.error(`[Network error]: ${networkError}`);
      }
    }

    if (graphQLErrors) {
      graphQLErrors.map(({ message }) =>
        console.error(`[GraphQL error]: ${message}`),
      );
    }
  });

  // const authLink = setContext((_, { headers }) => {
  //   // get the authentication token from local storage if it exists
  //   // const token = localStorage.getItem('authToken');
  //   // return the headers to the context so httpLink can read them
  //   let token = authToken;
  //
  //   if (!token) {
  //     const auth = authVar();
  //     token = auth.isAuthenticated ? auth.token : null;
  //   }
  //
  //   if (token) {
  //     return {
  //       headers: {
  //         ...headers,
  //         // authorization: token ? `Bearer ${authToken}` : "",
  //         authorization: `Bearer ${token}`,
  //       },
  //     };
  //   }
  //
  //   return {
  //     headers
  //   };
  // });

  return new ApolloClient({
    // link: authLink.concat(httpLink),
    link: from([
      reportErrors,
      httpLink,
    ]),
    cache: apolloCache,
  });
}
