import React from 'react';
import { gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Trans } from "react-i18next/icu.macro";
import { useTranslation } from "react-i18next";
import {
  MainContainer,
  MainHeadingSection,
  MainTitle,
  MainText,
} from "../../../shared/components/layout";

import {
  Form,
  Fieldset,
  Label,
  Input,
  ListBoxSelect,
  Checkbox,
  Error, Legend
} from "../../../shared/components/form";
import { ErrorContainer } from "../../../shared/components/form/Error";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { useSubmitTeamOnboarding } from "../useCases/useSubmitTeamOnboarding";
import { ensureUserIsAuthenticatedAndOnboardingIsNotCompleted } from "../Team.page";

Company.fragments = {
  onboarding: gql`
    fragment Company on TeamOnboarding {
      companyName
      country
      fleetSize
      usage
      firstName
      lastName
      phoneNumber
      accountingClosureMonth
      invitations
      customRates {
        label
        index
        bonus
        currency
      }
    }
  `,
};

export async function loader(args, context) {
  await ensureUserIsAuthenticatedAndOnboardingIsNotCompleted(context);

  const { data } = await context.apolloClient.query({
    query: gql`
      query GetOnboardingCompany {
        onboarding {
          memberId
          ... on TeamOnboarding {
            ...Company
          }
        }
      }
      ${Company.fragments.onboarding}
    `,
  });

  return {
    data: data.onboarding,
  };
}

export default function Company() {
  const { t } = useTranslation();
  const { data } = useLoaderData();
  const navigate = useNavigate();
  const { submitTeamOnboarding } = useSubmitTeamOnboarding();

  const methods = useForm({
    defaultValues: {
      companyName: data.companyName,
      fleetSize: data.fleetSize,
      usage: data.usage,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
    }
  });
  const { formState: { errors, isSubmitting } } = methods;

  const onSubmit = async (values) => {
    await submitTeamOnboarding(
      values.companyName,
      data.country,
      values.fleetSize,
      values.usage,
      values.firstName,
      values.lastName,
      values.phoneNumber,
      data.accountingClosureMonth,
      data.customRates?.map(({ label, index, bonus, currency }) => ({ label, index, bonus, currency })) || null,
      data.invitations,
      false,
    );

    navigate("/join/team/config");
  };

  // TODO: validations serveur
  // TODO: reset formulaire après submit success ? Vraiment nécessaire alors qu'il y a un redirect ?

  return (
    <MainContainer>
      <MainHeadingSection>
        <MainTitle>Votre entreprise</MainTitle>
        <MainText>
          Commençons par renseigner quelques informations sur votre entreprise :
        </MainText>
      </MainHeadingSection>

      <Form methods={methods} onSubmit={onSubmit}>
        <div className="space-y-5">
          <div>
            <Label htmlFor="companyName">
              <Trans i18nKey="team_company_companyName_label" components={{ sup: <sup /> }}>
                Nom de l'entreprise<sup>*</sup>
              </Trans>
            </Label>

            <Input
              id="companyName"
              name="companyName"
              type="text"
              placeholder={t('team_company_companyName_placeholder', 'Apple Inc...')}
              rules={{
                required: {
                  value: true,
                  message: (
                    <Trans i18nKey="team_company_companyName_error_required">
                      Information requise
                    </Trans>
                  ),
                }
              }}
            />
          </div>

          <div>
            <Label htmlFor="fleetSize">
              <Trans i18nKey="team_company_fleetSize_label" components={{ sup: <sup/> }}>
                Nombre de personnes concernées par les IK <sup>*</sup>
              </Trans>
            </Label>

            <ListBoxSelect
              id="fleetSize"
              name="fleetSize"
              placeholder={t('team_company_fleetSize_placeholder', 'Sélectionnez...')}
              options={[
                { value: 1, label: 'Moins de 10 personnes' },
                { value: 10, label: 'Entre 10 et 25 personnes' },
                { value: 26, label: 'Entre 26 et 50 personnes' },
                { value: 51, label: 'Entre 51 et 100 personnes' },
                { value: 101, label: '+ de 100 personnes' },
              ]}
              rules={{
                required: {
                  value: true,
                  message: (
                    <Trans i18nKey="team_company_fleetSize_error_required">
                      Nous avons besoin de cette information
                    </Trans>
                  ),
                }
              }}
            />
          </div>
        </div>

        <div className="space-y-5 pt-5">
          <Fieldset>
            <legend className="block text-sm font-medium">
              <Trans i18nKey="team_company_usage_label" components={{ sup: <sup /> }}>
                Votre utilisation d'Izika <sup>*</sup> :
              </Trans>
            </legend>
            <div className="space-y-2">
              <Checkbox
                id="usage-ik"
                name="usage"
                value="ik"
                rules={{
                  required: {
                    value: true,
                    message: (
                      <Trans i18nKey="team_company_usage_error_required">
                        Veuillez faire au moins un choix
                      </Trans>
                    ),
                  }
                }}
              >
                <Trans i18nKey="team_company_usage_ik_label">
                  J'effectue des déplacements soumis à IK
                </Trans>
              </Checkbox>

              <Checkbox
                id="usage-manager"
                name="usage"
                value="manager"
                rules={{
                  required: {
                    value: true,
                    message: (
                      <Trans i18nKey="team_company_usage_error_required">
                        Veuillez faire au moins un choix
                      </Trans>
                    ),
                  }
                }}
              >
                <Trans i18nKey="team_company_usage_manager_label">
                  Je gère des personnes soumises aux IK
                </Trans>
              </Checkbox>
            </div>

            <Error name="usage"/>
          </Fieldset>

          <fieldset className="mt-6">
            <Legend>
              <Trans i18nKey="team_company_name_label" components={{ sup: <sup /> }}>
                Quel est votre nom ? <sup>*</sup>
              </Trans>
            </Legend>
            <div>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                placeholder={t('team_company_firstName_placeholder', 'Prénom')}
                rules={{
                  required: true,
                }}
                showErrors={false}
              />
              <Input
                id="lastName"
                name="lastName"
                type="text"
                placeholder={t('team_company_lastName_placeholder', 'Nom')}
                rules={{
                  required: true,
                }}
                showErrors={false}
              />
            </div>

            {(errors.firstName || errors.lastName) && (
              <ErrorContainer>
                <Trans i18nKey="team_company_name_error_required">
                  Nous avons besoin de cette information
                </Trans>
              </ErrorContainer>
            )}
          </fieldset>

          <div>
            <Label htmlFor="phoneNumber">
              <Trans i18nKey="team_company_phoneNumber_label">
                Quel est votre numéro de téléphone ?
              </Trans>
            </Label>
            <Input
              name="phoneNumber"
              id="phoneNumber"
              type="text"
              placeholder={t('team_company_phoneNumber_placeholder', '06 06 06 06 06')}
            />
          </div>

          <SubmitButton loading={isSubmitting}>
            <Trans i18nKey="team_company_submit">
              Continuer
            </Trans>
          </SubmitButton>
        </div>
      </Form>
    </MainContainer>
  );
}
