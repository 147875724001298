import React from 'react';
import ReactDOM from 'react-dom';
import initApolloClient from "./config/apollo-client";
import initI18n from "./config/i18n";
import { ApolloProvider } from "@apollo/client";
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import './assets/styles/index.scss';
import Team from "./pages/team/Team.page";
import Solo from "./pages/solo/Solo.page";
import MainLoading from "./pages/MainLoading.page";
import Company, { loader as companyLoader } from "./pages/team/components/Company";
import Config, { loader as configLoader } from "./pages/team/components/Config";
import Invite, { loader as inviteLoader } from "./pages/team/components/Invite";
import WhatNow, { loader as whatNowLoader } from "./pages/team/components/WhatNow";
import SignUpTeam from "./pages/team/components/SignUp";
import SignUpSolo from "./pages/solo/components/SignUp";
import ConfirmInvitation, { loader as confirmInvitationLoader, action as confirmInvitationAction } from "./pages/team/components/ConfirmInvitation";
import ErrorPage from "./pages/Error.page";
import ChooseProduct from "./pages/chooseProduct/ChooseProduct.page";
import SignIn from "./pages/signIn/SignIn.page";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword.page";
import ResetPassword, { loader as resetPasswordLoader } from "./pages/forgotPassword/ResetPassword.page";

const rootElement = document.getElementById("root");

let authToken = null;
if (typeof rootElement.dataset.token !== "undefined" && rootElement.dataset.token !== "") {
  authToken = rootElement.dataset.token;
}

const apolloClient = initApolloClient(authToken);
initI18n();

const routeLoaderContext = {
  apolloClient,
};

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        exact: true,
        element: <Navigate to="/sign-in" replace={true} />,
      },
      {
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
        loader: resetPasswordLoader,
      },
      {
        path: 'join',
        children: [
          {
            index: true,
            exact: true,
            element: <ChooseProduct />,
          },
          {
            path: "team/confirm-invitation/:token",
            element: <ConfirmInvitation />,
            loader: confirmInvitationLoader,
            action: confirmInvitationAction,
          },
          {
            path: "team",
            element: <Team />,
            children: [
              {
                index: true,
                element: <SignUpTeam />,
              },
              {
                path: "company",
                element: <Company />,
                loader: args => companyLoader({ ...args }, routeLoaderContext),
              },
              {
                path: "config",
                element: <Config />,
                loader: args => configLoader({ ...args }, routeLoaderContext),
              },
              {
                path: "invitations",
                element: <Invite />,
                loader: args => inviteLoader({ ...args }, routeLoaderContext),
              },
              {
                path: "what-now",
                element: <WhatNow />,
                loader: args => whatNowLoader({ ...args }, routeLoaderContext),
              },
            ],
          },
          {
            path: "solo/*",
            element: <Solo />,
            children: [
              {
                index: true,
                element: <SignUpSolo />,
              },
            ],
          },
        ]
      }
    ]
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} fallbackElement={<MainLoading />}/>
    </ApolloProvider>
  </React.StrictMode>,
  rootElement
);
