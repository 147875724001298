import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import countries from "../intl/countries.fr-FR.json";

// On met en avant les pays francophones pour le moment
const preferredCountries = ["FR", "CH", "BE", "LU"];

function createOptions() {
  return [
    {
      label: null,
      options: preferredCountries.map((value) => ({
        label: countries[value],
        value,
      })),
    },
    {
      label: null,
      options: Object.keys(countries).map((value) => ({
        label: countries[value],
        value,
      })),
    },
  ];
}

function GroupHeading(props) {
  return null;
}

function formatOptionLabel({ label, value }) {
  return (
    <>
      <span className={`fi fi-${value.toLowerCase()}`} /> {label}
    </>
  );
}

const CountrySelect = forwardRef(function CountrySelect(
  { id, name, defaultValue, value, disabled, placeholder, onChange, onBlur },
  ref,
) {
  const options = useMemo(() => createOptions(), []);
  const defaultValueOption = useMemo(
    () => options[1].options.find((option) => option.value === defaultValue),
    [options, defaultValue],
  );
  const valueOption = useMemo(() => options[1].options.find((option) => option.value === value), [options, value]);

  return (
    <Select
      ref={ref}
      id={id}
      name={name}
      defaultValue={defaultValueOption}
      value={valueOption}
      closeOnSelect={true}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={true}
      hideSelectedOptions={false}
      options={options}
      onChange={onChange}
      onBlur={(event) => onBlur(event, valueOption)}
      placeholder={placeholder || "Sélectionnez votre pays"}
      selectProps={{ autoComplete: 0 }}
      formatOptionLabel={formatOptionLabel}
      className="country-multiselect"
      classNamePrefix="country-multiselect"
      components={{ GroupHeading }}
    />
  );
});

CountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

CountrySelect.defaultProps = {
  defaultValue: null,
  disabled: false,
  value: null,
  placeholder: null,
  onChange: () => {},
  onBlur: () => {},
};

export default CountrySelect;
