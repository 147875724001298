import clsx from "clsx";
import React from "react";

function MainTitle({ children, className }) {
  return (
      <h2 className={clsx(
        'mt-6 text-4xl font-heading',
        className
      )}>
        {children}
      </h2>
  );
}

export default MainTitle;
