import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

export default function MainLoading() {
    return (
      <div className="flex flex-1 flex-col bg-gradient-to-tr from-white to-brown-B50 items-center justify-center">
        <main>
          <FontAwesomeIcon icon={faSpinnerThird} spin size="2x" fixedWidth />
        </main>
      </div>
    );
}
