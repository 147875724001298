import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import ConnectForm from "./ConnectForm";
import get from "lodash/get";

export function ErrorContainer({ children, className }) {
  return (
    <p className={clsx(
      'mt-2 text-sm text-orange-O500',
      className
    )}>
      {children}
    </p>
  );
}

function Error({ name, children, className }) {
  if (name) {
    // un nom de champ a été spécifié, on connect le context du form pour récupérer l'erreur
    return (
      <ConnectForm>
        {({ formState: { errors } }) => {
          const inputError = get(errors, name);

          return inputError ? (
            <ErrorContainer className={className}>
              {inputError.message}
            </ErrorContainer>
          ) : null;
        }}
      </ConnectForm>
    );
  }

  return (
    <ErrorContainer className={className}>
      {children}
    </ErrorContainer>
  );
}

Error.propTypes = {
  name: PropTypes.string,
};

export default Error;
