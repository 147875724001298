import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function Label({ htmlFor, children, className, ...other }) {
  return (
    <label
      className={clsx(
        'block text-sm font-medium',
        className
      )}
      htmlFor={htmlFor}
      {...other}
    >
      {children}
    </label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
};

export default Label;
