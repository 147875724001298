import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import get from 'lodash/get';
import { Controller, useFormContext } from "react-hook-form";
import CountrySelectComponent from "../CountrySelect";

function CountrySelect({ id, name, rules, disabled, className }) {
  const { control, formState: { errors } } = useFormContext();
  const inputError = get(errors, name);

  return (
    <div className="mt-1">
      <Controller
        render={({
          field: { onChange, onBlur, value },
        }) => (
          <CountrySelectComponent
            id={id}
            name={name}
            onChange={(value) => onChange(value.value)}
            onBlur={(value) => onBlur(value.value)}
            value={value}
            disabled={disabled}
            placeholder="Sélectionner un pays"
          />
        )}
        name={name}
        control={control}
        rules={rules}
      />

      {inputError && (
        <Error>
          {inputError.message}
        </Error>
      )}
    </div>
  );
}

CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

CountrySelect.defaultProps = {
  rules: {},
  disabled: false,
};

export default CountrySelect;
