import axios from "axios";

export async function getEmailFromResetPasswordToken(token) {
  const response = await axios.get(
    `${window.ENV.API_URL}/security/email-from-reset-password-token?resetPasswordToken=${token}`,
    {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  );

  return response.data?.data;
}

async function doMutationRequest(endpoint, data) {
  try {
    await axios.post(`${window.ENV.API_URL}${endpoint}`, data, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    return {
      success: true,
      error: undefined,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response.data.error,
    };
  }
}

export const requestResetPassword = (email) => doMutationRequest("/security/request-reset-password", {
  email,
});

export const resetPassword = (token, password) => doMutationRequest("/security/reset-password", {
  token,
  password,
});
