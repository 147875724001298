import React from 'react';
import { Link } from "react-router-dom";
import logoSolo from '../../assets/img/logo-izika-solo.png';
import logoTeam from '../../assets/img/logo-izika-team.png';

export default function ChooseProduct() {
    return (
      <main className="h-full flex flex-col flex-1">

        <div className=" flex flex-col md:flex-row flex-1">
          <div className="flex justify-center flex-col flex-1 p-5 bg-yellow-Y40">

            <div className="mx-auto max-w-md text-md md:max-w-1xl">

              <h1 className="font-heading flex flex-col p-8">
                <span className="text-lg uppercase"><img src={logoSolo} alt="logo izika" className="w-20"/></span>
              </h1>
              <div className="ring-brown-B500 rounded-3xl p-8 ring-1 xl:p-10">
                <h3 id="tier-freelancer" className="text-gray-900 text-lg font-semibold leading-8">
                  1 utilisateur
                </h3>
                <p className="text-gray-600 mt-4 text-sm leading-5">
                  Vous êtes indépendant, entrepreneur, TPE, salarié déclarant ses IK personnellement...
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-gray-900 text-4xl font-bold tracking-tight">8,25€</span>
                  <span className="text-gray-600 text-sm font-semibold leading-5">HT/mois<sup>*</sup></span>
                </p>
                <p className="text-gray-600 text-xs font-semibold leading-4">
                  <i className="fa-solid fa-badge"></i>
                  Gratuit jusqu'à 10 déplacements par mois
                </p>

                <Link to="solo"
                      className="bg-brown-B700 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600 mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  Créer mon compte izika Solo</Link>
                <ul className="text-gray-600 mt-8 space-y-3 text-sm leading-6 xl:mt-10">
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Saisie automatique des déplacements depuis votre agenda en ligne
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Calcul des IK à partir des adresses de vos RDV
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Relevé d'IK conforme aux normes fiscales
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Gestion des tournées, adresses favorites...
                  </li>

                </ul>
              </div>
              <div className="sm:flex sm:justify-center items-center text-xs mt-1">
                * paiement annuel : soit 99€HT/an
              </div>

            </div>
          </div>

          <div
            className="flex justify-center flex-col items-center flex-1 p-5 bg-brown-B100">

            <div className="mx-auto my-8 max-w-md text-md md:max-w-1xl">

              <h1 className="font-heading flex flex-col p-8">
                <span className="text-lg uppercase"><img src={logoTeam} alt="logo izika team" className="w-20"/></span>
              </h1>
              <div className="ring-brown-B500 rounded-3xl p-8 ring-1 xl:p-10">
                <h3 id="tier-freelancer" className="text-gray-900 text-lg font-semibold leading-8">
                  multi-utilisateurs
                </h3>
                <p className="text-gray-600 mt-4 text-sm leading-5">
                  Vous êtes une entreprise, collectivité, association avec une flotte de véhicules aux IK
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-gray-900 text-4xl font-bold tracking-tight">10€</span>
                  <span className="text-gray-600 text-sm font-semibold leading-5">HT/mois /utilisateur</span>
                </p>
                <p className="text-gray-600 text-xs font-semibold leading-5">
                  <i className="fa-solid fa-badge"></i>
                  2 mois d'essai pour tester les fonctionnalités
                </p>

                <Link to="team"
                      className="bg-yellow-Y200 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600 mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  Créer mon compte izika Team</Link>
                <ul className="text-gray-600 mt-8 space-y-3 text-sm leading-6 xl:mt-10">
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Gestion de flotte
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Flux d'approbation et suivi des demandes d'IK
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Gestion d'équipes, ventilation par dossiers
                  </li>
                  <li className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                         className="text-indigo-600 h-6 w-5 flex-none">
                      <path fillRule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clipRule="evenodd"></path>
                    </svg>
                    Toutes les fonctionnalités d'izika Solo pour vos collaborateurs terrain
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>

        <div className="flex justify-center text-sm text-white font-semibold gap-2 bg-brown-B900 px-6 py-2.5 sm:px-3.5 ">
          <p className="">
            <svg viewBox="0 0 2 2" className="mx-1 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1}/>
            </svg>
            <a href="https://izika.com" className="whitespace-nowrap ">
              <span aria-hidden="true">&rarr;</span> Retrouvez tous les détails de nos offres sur <span className="underline">izika.com</span>
            </a>

          </p>
          <span>/</span>
          <p className="text-sm">Déjà un compte ? <Link className="underline" to="/sign-in">Connectez-vous</Link></p>
        </div>

        <div></div>

      </main>
    );
}
