import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";
import get from 'lodash/get';
import { Controller, useFormContext } from "react-hook-form";
import MonthSelectComponent from "../MonthSelect";

function MonthSelect({ id, name, rules, disabled, className }) {
  const { control, formState: { errors } } = useFormContext();
  const inputError = get(errors, name);

  return (
    <div className="mt-1">
      <Controller
        render={({
          field: { onChange, onBlur, value },
        }) => (
          <MonthSelectComponent
            id={id}
            onChange={(value) => onChange(value.value)}
            onBlur={(value) => onBlur(value.value)}
            value={value}
            disabled={disabled}
            placeholder="Choisir un mois"
          />
        )}
        name={name}
        control={control}
        rules={rules}
      />

      {inputError && (
        <Error>
          {inputError.message}
        </Error>
      )}
    </div>
  );
}

MonthSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

MonthSelect.defaultProps = {
  rules: {},
  disabled: false,
};

export default MonthSelect;
