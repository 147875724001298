import {useState, useCallback, useEffect, useRef} from "react";
import isEqual from 'lodash/isEqual';
import axios from "axios";

export function useCheckUsernameExists() {
  const [result, setResult] = useState({
    loading: false,
  });

  const ref = useRef({
    result,
    isMounted: true,
  });

  {
    Object.assign(ref.current);
  }

  const execute = useCallback(async (username) => {
    if (!ref.current.result.loading) {
      setResult(ref.current.result = {
        loading: true,
        error: void 0,
        data: void 0,
      });
    }

    let endpoint = `${window.ENV.API_URL}/security/sign-up/email`;

    try {
      const response = await axios.post(endpoint, {
        email: username,
      });

      const result = {
        loading: false,
        data: response.data.data.found,
        error: void 0,
      };

      if (ref.current.isMounted && !isEqual(ref.current.result, result)) {
        setResult(ref.current.result = result);
      }

      return response;
    } catch (error) {
      if (ref.current.isMounted) {
        const result = {
          loading: false,
          error,
          data: void 0,
        };

        if (!isEqual(ref.current.result, result)) {
          setResult(ref.current.result = result);
        }
      }

      throw error;
    }
  }, []);

  useEffect(() => {
    ref.current.isMounted = true;

    return () => {
      ref.current.isMounted = false;
    };
  }, []);

  return [execute, { ...result }];
}
