import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";

function Checkbox({ id, name, value, rules, children, className }) {
  const { register } = useFormContext();

  return (
    <div className="flex items-center">
      <input
        {...register(name, { ...rules })}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className={clsx(
          'h-4 w-4 rounded border-brown-B500 text-yellow-Y100 focus:ring-yellow-Y60',
          className
        )}
      />

      <label htmlFor={id} className="ml-2 block text-sm">
        {children}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rules: PropTypes.object,
};

Checkbox.defaultProps = {
  rules: {},
};

export default Checkbox;
