import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";
import fr from "../translations/app/fr.json";
import moment_fr from "../translations/moment/fr.js";
import moment from "moment";

export default function initI18n() {
  // moment
  moment.locale("fr", moment_fr);

  // react-i18next
  return i18n
    .use(ICU)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      debug: "development" === process.env.NODE_ENV,
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        fr,
      },
      lng: "fr",
      fallbackLng: "fr",
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
      },
    });
}
