import countries from "./countries.fr-FR.json";
import currencies from "./currencies.json";
import countriesCurrenciesMap from "./countries-currencies.json";

/**
 * @typedef {Object} Currency
 * @property {keyof typeof currencies} code
 * @property {string} alphabeticCode
 * @property {string} currency
 * @property {number} minorUnit
 * @property {number} numericCode
 */

/**
 * @typedef {Object} Country
 * @property {string} code
 * @property {string} name
 * @property {Currency} currency
 */

/**
 * @param {string} countryCode
 * @return {Country}
 */
export function getCountry(countryCode) {
  const countryCurrencies = countriesCurrenciesMap[countryCode] || null;
  if (!countryCurrencies) {
    throw new Error(`No currency found for country code ${countryCode}`);
  }

  // if (countryCurrencies.length > 0) {
  //   // TODO: décider quoi faire si le pays a plusieurs currencies
  // }

  const currencyCode = countryCurrencies[0];
  const countryCurrency = currencies[currencyCode];

  return {
    code: countryCode,
    name: countries[countryCode],
    currency: {
      code: currencyCode,
      ...countryCurrency,
    }
  }
}
