import React from 'react';
import { useLocation, matchPath } from "react-router-dom";
import Step from "./Step";
import logoTeam from "../../../assets/img/logo-izika-team.png";

const steps = [
  {
    name: 'Informations de connexion',
    description: 'Cursus semper viverra facilisis et et some more.',
    path: "/join/team"
  },
  {
    name: 'Votre entreprise',
    description: 'Vitae sed mi luctus laoreet.',
    path: "/join/team/company"
  },
  {
    name: 'Barème',
    description: "Définissez les barèmes de calculs d'IK",
    path: "/join/team/config"
  },
  {
    name: 'Collaborateurs',
    description: 'Invitez vos collaborateurs à rejoindre IzikaTeam',
    path: "/join/team/invitations"
  },
  {
    name: 'Preview',
    description: 'Iusto et officia maiores porro ad non quas.',
    path: "/join/team/what-now"
  },
];

export default function Steps() {
  const { pathname } = useLocation();
  // on cherche l'index de l'étape qui correspond à l'URL actuelle
  const currentStepIndex = steps.findIndex(step => matchPath(step.path, pathname));

  return (
      // Static sidebar for desktop
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-[32rem] md:flex-col items-center justify-center bg-yellow-Y40">
        {/* Sidebar component */}
        <nav aria-label="Progress" className="m-5">
          <div className="flex flex-col mb-8">
            <img src={logoTeam} alt="logo izika team" className="w-24" />
          </div>
          <ol role="list" className="overflow-hidden">
            {steps.map((step, index) => {
              const current = currentStepIndex === index;
              const upcoming = index > currentStepIndex;

              return (
                <Step
                  key={index}
                  name={step.name}
                  description={step.description}
                  status={
                    current
                      ? 'current'
                      : (upcoming ? 'upcoming' : 'complete')
                  }
                  isLast={index === steps.length - 1}
                />
              );
            })}
          </ol>
        </nav>
      </div>
  );
}
