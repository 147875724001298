import clsx from "clsx";
import React from "react";

function MainHeadingSection({ children, className }) {
  return (
    <div className={clsx(
      'pb-6',
      className
    )}>
      {children}
    </div>
  );
}

export default MainHeadingSection;
