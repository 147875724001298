import React from "react";
import clsx from "clsx";

function Legend({ children, className, ...other }) {
  return (
    <legend
      className={clsx(
        'block text-sm font-medium',
        className
      )}
      {...other}
    >
      {children}
    </legend>
  );
}

export default Legend;
