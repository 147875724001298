/**
 * @param {string} name
 * @param {object} properties
 */
export function track(name, properties) {
  if (typeof window.Izika.analytics.tracker !== "undefined") {
    window.Izika.analytics.tracker.track(name, properties);
  }
}

/**
 * @param {string} name
 * @param {object} properties
 */
export function trackEventOnce(name, properties) {
  if (hasTrackedEvent(name)) {
    return;
  }

  track(name, properties);
  recordEventTracked(name);
}

/**
 * @param {string} eventName
 */
export function clearTrackedEvent(eventName) {
  const trackedEvents = getTrackedEvents();
  const index = trackedEvents.indexOf(eventName);
  if (-1 !== index) {
    trackedEvents.splice(index, 1);
  }

  sessionStorage.setItem("trackedEvents", JSON.stringify(trackedEvents));
}

/**
 * @param {string} eventName
 * @return {boolean}
 */
function hasTrackedEvent(eventName) {
  return getTrackedEvents().includes(eventName);
}

/**
 * @returns {string[]}
 */
function getTrackedEvents() {
  return JSON.parse(sessionStorage.getItem("trackedEvents") || "[]");
}

/**
 * @param {string} eventName
 */
function recordEventTracked(eventName) {
  const trackedEvents = getTrackedEvents();
  if (!trackedEvents.includes(eventName)) {
    trackedEvents.push(eventName);
  }

  sessionStorage.setItem("trackedEvents", JSON.stringify(trackedEvents));
}
