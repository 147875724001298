import React from 'react';
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next/icu.macro";
import { useTranslation } from "react-i18next";
import { useCheckUsernameExists } from "../../../shared/useCases/useCheckEmailExists";
import { MainContainer, MainHeadingSection, MainText, MainTitle } from "../../../shared/components/layout";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { useSignUp } from "../../../shared/useCases/useSignUp";
import logoGoogle from "../../../assets/img/ico-google.png";

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signUp } = useSignUp();

  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm();
  const [checkUsernameExists] = useCheckUsernameExists();

  const onSubmit = async (values) => {
    await signUp(values.email, values.password, 'team');

    navigate("/join/team/company");
  };

  // TODO: validations serveur
  // TODO: reset formulaire après submit success ? Vraiment nécessaire alors qu'il y a un redirect ?

  return (
    <MainContainer>
      <MainHeadingSection>
        <MainTitle>Créez votre compte</MainTitle>
        <MainText>
          Déjà un compte ?{' '}
          <Link to="/sign-in" className="font-medium text-yellow-Y200 hover:text-yellow-Y400">
            Connectez-vous
          </Link>
        </MainText>
      </MainHeadingSection>

      <div className="mt-8">
        <form noValidate="novalidate" onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Adresse e-mail <sup>*</sup>
            </label>
            <div className="mt-1">
              <input
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                  validate: {
                    notExists: async (value) => {
                      const response = await checkUsernameExists(value);

                      return !response.data.data.found;
                    }
                  }
                })}
                id="email"
                name="email"
                type="email"
                placeholder={t('team_sign_up_email_placeholder', 'Adresse e-mail')}
                autoComplete="email"
                autoFocus
                required
                disabled={isSubmitting}
                className="block w-full appearance-none rounded-md border border-brown-B500 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60 sm:text-sm"
              />
              {errors.email && (
                <div className="text-sm text-orange-O500">
                  {errors.email.type === "required" && (
                    <Trans i18nKey="team_sign_up_email_error_required">
                      L'adresse e-mail est obligatoire
                    </Trans>
                  )}
                  {errors.email.type === "pattern" && (
                    <Trans i18nKey="team_sign_up_email_error_pattern">
                      Cette adresse e-mail ne semble pas valide
                    </Trans>
                  )}
                  {errors.email.type === "notExists" && (
                    <Trans i18nKey="team_sign_up_email_error_not_exists">
                      Cette adresse email est déjà utilisée
                    </Trans>
                  )}
                </div>
              )}

            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium">
              <Trans i18nKey="team_sign_up_password_label" components={{ sup: <sup /> }}>
                Mot de passe <sup>*</sup>
              </Trans>
            </label>
            <div className="mt-1">
              <input
                {...register('password', { required: true, minLength: 8, })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder={t('team_sign_up_password_placeholder', 'Mot de passe')}
                disabled={isSubmitting}
                required
                className="block w-full appearance-none rounded-md border border-brown-B500 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60 sm:text-sm"
              />
              {errors.password && (
                <div className="text-sm text-orange-O500">
                  {errors.password.type === "required" && (
                    <Trans i18nKey="team_sign_up_password_error_required">
                      Le mot de passe est obligatoire
                    </Trans>
                  )}
                  {errors.password.type === "minLength" && (
                    <Trans i18nKey="team_sign_up_password_error_pattern">
                      Le mot de passe doit contenir au moins 8 caractères
                    </Trans>
                  )}
                </div>
              )}
            </div>
          </div>

          {/*<div className="flex items-center justify-between">*/}
          {/*  <div className="flex items-center">*/}
          {/*    <input*/}
          {/*      id="remember-me"*/}
          {/*      name="remember-me"*/}
          {/*      type="checkbox"*/}
          {/*      className="h-4 w-4 rounded border-brown-B500 text-yellow-Y100 focus:ring-yellow-Y60"*/}
          {/*    />*/}
          {/*    <label htmlFor="remember-me" className="ml-2 block text-sm">*/}
          {/*      Enregistrer mes informations*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div>
            <SubmitButton loading={isSubmitting}>
              <Trans i18nKey="team_sign_up_submit">
                Continuer
              </Trans>
            </SubmitButton>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-brown-B500"/>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-brown-B600">Ou</span>
            </div>
          </div>

          <div className="mt-6">
            <a
              href="/connect/google?state=eyJwcm9kdWN0IjoidGVhbSJ9"
              className="inline-flex items-center w-full justify-center rounded-md border border-brown-B500 gap-1 bg-white py-2 px-4 text-sm font-medium text-brown-B600 shadow-sm hover:bg-gray-50"
            >
              <img src={logoGoogle} width="24" />
              <span >S'inscrire avec mon compte Google</span>
            </a>
          </div>
        </div>

      </div>
    </MainContainer>
  );
}
