import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import clsx from "clsx";

function SubmitButton({ theme, loading, disabled, children, className }) {
  return (
    <button
      type="submit"
      disabled={loading || disabled}
      className={clsx(
        "flex w-full justify-center py-2 px-4",
        "rounded-md",
        "text-sm font-medium text-white shadow-sm",
        "inline-flex items-center",
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        theme === "team" && "bg-yellow-Y100 hover:bg-yellow-Y200 focus:ring-yellow-Y60",
        theme === "solo" && "bg-brown-B700 hover:bg-brown-B700 focus:ring-brown-B500",
        className
      )}
    >
      {loading ? (
        <>
          <FontAwesomeIcon icon={faSpinnerThird} spin fixedWidth />{' '}
          {children}
        </>
      ) : children}
    </button>
  );
}

SubmitButton.propTypes = {
  theme: PropTypes.oneOf(['solo', 'team']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
  theme: 'team',
  loading: false,
  disabled: false,
};

export default SubmitButton;
