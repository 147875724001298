import React from 'react';
import PropTypes from "prop-types";
import { CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export default function Step({ name, description, status, isLast }) {
  return (
    <li key={name} className={clsx(!isLast ? 'pb-10' : '', 'relative')}>
      {'complete' === status ? (
        <>
          {!isLast ? (
            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-yellow-Y100" aria-hidden="true" />
          ) : null}
          <div className="group relative flex items-center">
          <span className="flex h-9 items-center">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-yellow-Y100">
              <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </span>
            <span className="ml-4 flex min-w-0 flex-col">
            <span className="text-xs font-heading uppercase font-bold text-yellow-Y200">{name}</span>
              {/*<span className="text-sm text-gray-500">{description}</span>*/}
          </span>
          </div>
        </>
      ) : 'current' === status ? (
        <>
          {!isLast ? (
            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-yellow-Y100/50" aria-hidden="true" />
          ) : null}
          <div className="group relative flex items-center" aria-current="step">
          <span className="flex h-9 items-center" aria-hidden="true">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-yellow-Y100 bg-yellow-Y40">
              <span className="h-2.5 w-2.5 rounded-full bg-yellow-Y100" />
            </span>
          </span>
            <span className="ml-4 flex min-w-0 flex-col">
            <span className="text-xs font-heading uppercase font-bold text-brown-B900">{name}</span>
              {/*<span className="text-sm text-gray-500">{description}</span>*/}
          </span>
          </div>
        </>
      ) : (
        <>
          {!isLast ? (
            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-yellow-Y100/50" aria-hidden="true" />
          ) : null}
          <div className="group relative flex items-center">
          <span className="flex h-9 items-center" aria-hidden="true">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-yellow-Y100/50 bg-yellow-Y40">
              <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
            </span>
          </span>
            <span className="ml-4 flex min-w-0 flex-col">
            <span className="text-xs font-heading uppercase font-bold text-brown-B900/50">{name}</span>
              {/*<span className="text-sm text-gray-500">{description}</span>*/}
          </span>
          </div>
        </>
      )}
    </li>
  );
}

Step.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['current', 'complete', 'upcoming']).isRequired,
  isLast: PropTypes.bool,
};

Step.defaultProps = {
  isLast: false,
};
