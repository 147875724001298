import clsx from "clsx";
import React from "react";

function MainText({ children, className }) {
  return (
      <p className={clsx(
        'mt-2 text-sm',
        className
      )}>
        {children}
      </p>
  );
}

export default MainText;
