import React from 'react';
import { Link, useNavigate, useSearchParams, json, useLoaderData } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MainTitle } from "../../shared/components/layout";
import logoGoogle from "../../assets/img/ico-google.png";
import logoColor from "../../assets/img/logo-izika-color_x400.png";
import SubmitButton from "../../shared/components/form/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faLongArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { getEmailFromResetPasswordToken, resetPassword } from "./useCases/useForgotPassword";

export async function loader({ request }) {
  const url = new URL(request.url);
  const token = url.searchParams.get("resetPasswordToken");

  if (!token) {
    return json({
      email: null,
    });
  }

  const result = await getEmailFromResetPasswordToken(token);

  return json({
    email: result?.email,
  });
}


export default function ResetPassword() {
  const { email } = useLoaderData();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const token = searchParams.get("resetPasswordToken");
  const watchedPassword = watch("password");

  const onSubmit = async (values) => {
    const result = await resetPassword(token, values.password);

    if (result.error) {
      setError('root.serverError', {
        type: 'server',
      });

      return;
    }

    navigate(`/sign-in?rp&email=${email}`);
  };

  return (
    <>
      <div className={`
        bg-gradient-to-br from-white to-yellow-Y50
         flex min-h-full flex-1 flex-col justify-center pt-6 lg:pt-8 pb-12 sm:px-6 lg:px-8
     `}>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logoColor}
            alt="Izika"
          />

          <MainTitle className="text-center">Créer un nouveau mot de passe</MainTitle>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="">

              {(!email || errors.root?.serverError?.type === 'server') && (
                <div className="rounded-md bg-brown-B50 p-4 mb-3">
                  <div className="flex">
                    <div className="flex-shrink-0 text-red-R500">
                      <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Cette requête de réinitialisation de mot de passe ne semble plus valide.
                        <br />
                        <br />
                        <Link to="/forgot-password" className="underline">Faire une nouvelle demande de réinitialisation</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {email && (
                <form noValidate="novalidate" onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium">
                      Adresse E-mail : <strong>{email}</strong>
                    </label>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium">
                      Mot de passe
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('password',{ required: true })}
                        id="password"
                        name="password"
                        type="password"
                        autoFocus
                        placeholder="Mot de passe"
                        disabled={isSubmitting}
                        required
                        className="block w-full appearance-none rounded-md border border-yellow-Y100 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-offset-2 focus:ring-yellow-Y60 focus:ring-2 sm:text-sm"
                      />
                      {errors.password && (
                        <div className="text-sm text-orange-O500">
                          {errors.password.type === "required" && "Le mot de passe est obligatoire"}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="repeat-password" className="block text-sm font-medium">
                      Confirmer le mot de passe
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('repeatPassword',{
                          required: true,
                          validate: {
                            mismatch: (value) => {
                              return value === watchedPassword;
                            },
                          },
                        })}
                        id="repeat-password"
                        name="repeatPassword"
                        type="password"
                        placeholder="Confirmer le mot de passe"
                        disabled={isSubmitting}
                        required
                        className="block w-full appearance-none rounded-md border border-yellow-Y100 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-offset-2 focus:ring-yellow-Y60 focus:ring-2 sm:text-sm"
                      />
                      {errors.repeatPassword && (
                        <div className="text-sm text-orange-O500">
                          {errors?.repeatPassword.type === "required"
                            ? "Le mot de passe est obligatoire"
                            : (
                              errors?.repeatPassword.type === "mismatch" && "Les mots de passe ne correspondent pas"
                            )
                          }
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <SubmitButton loading={isSubmitting} theme="team">
                      Enregistrer mon nouveau mot de passe
                    </SubmitButton>
                  </div>
                </form>
              )}
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-brown-B200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-brown-B500">Ou </span>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/sign-in" className="text-sm underline"><FontAwesomeIcon icon={faLongArrowLeft} className="mr-2" />Retour à la page de connexion</Link>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Pas encore de compte ?{' '}
            <Link to="/join" className="font-semibold leading-6 underline">
              Créer un compte
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
