import axios from "axios";
import { authVar } from "../../config/apollo-cache";

export function logout() {
  authVar({
    isAuthenticated: false,
  });
}

export function markAuthenticated() {
  authVar({
    isAuthenticated: true,
  });
}

const authenticateEndpoint = `${window.ENV.API_URL}/security/sign-in`;
export async function authenticate(username, password, targetPath) {
  try {
    const response = await axios.post(authenticateEndpoint, {
      username,
      password,
      "_target_path": targetPath,
    }, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });

    markAuthenticated();

    return {
      success: true,
      error: undefined,
      redirectTo: response.headers['x-redirect-location'] ?? undefined,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.error ?? "authentication_failed",
      redirectTo: undefined,
    };
  }
}
