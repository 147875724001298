import React from "react";
import clsx from "clsx";

function Fieldset({ children, className, ...other }) {
  return (
    <fieldset
      className={clsx(
        'space-y-5',
        className
      )}
      {...other}
    >
      {children}
    </fieldset>
  );
}

export default Fieldset;
