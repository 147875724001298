import clsx from "clsx";

function MainContainer({ children, className }) {
  return (
    <div className={clsx(
      'flex flex-1 flex-col h-full',
      'bg-gradient-to-tr from-white to-brown-B50',
      'items-center justify-center py-24 md:pl-[32rem]',
      className
    )}>
      <main>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {children}
        </div>
      </main>
    </div>
  );
}

export default MainContainer;
