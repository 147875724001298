import React, { useEffect } from 'react';
import { gql } from "@apollo/client";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { Trans } from "react-i18next/icu.macro";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Label, MonthSelect, Input } from "../../../shared/components/form";
import { MainContainer, MainHeadingSection, MainText, MainTitle } from "../../../shared/components/layout";
import { useTranslation } from "react-i18next";
import Radio from "../../../shared/components/form/Radio";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { ErrorContainer } from "../../../shared/components/form/Error";
import { useSubmitTeamOnboarding } from "../useCases/useSubmitTeamOnboarding";
import { ensureUserIsAuthenticatedAndOnboardingIsNotCompleted } from "../Team.page";
import CountrySelect from "../../../shared/components/form/CountrySelect";
import { getCountry } from "../../../shared/intl/intl";

Config.fragments = {
  onboarding: gql`
    fragment Config on TeamOnboarding {
      companyName
      country
      fleetSize
      usage
      firstName
      lastName
      phoneNumber
      accountingClosureMonth
      customRates {
        label
        index
        bonus
        currency
      }
      invitations
    }
  `,
};

export async function loader(args, context) {
  await ensureUserIsAuthenticatedAndOnboardingIsNotCompleted(context);

  const { data } = await context.apolloClient.query({
    query: gql`
      query GetOnboardingConfig {
        onboarding {
          memberId
          ... on TeamOnboarding {
            ...Config
          }
        }
      }
      ${Config.fragments.onboarding}
    `,
  });

  return {
    data: data.onboarding,
  };
}

function getValidationSchema(t) {
  return yup.object({
    country: yup.string().required(t('team_config_country_error_required')),
    accountingClosureMonth: yup.string().required(t('team_config_accountingClosureMonth_error_required')),
    rateType: yup.string().oneOf(['country', 'custom']).required(),
    customRates: yup.array().when('rateType', {
      is: 'custom',
      then: (schema) => schema.of(yup.object({
        index: yup
          .number().typeError(t('team_config_customRates_index_error'))
          .positive(t('team_config_customRates_index_error'))
          .required(t('team_config_customRates_index_error_required')),
        bonus: yup
          .number().typeError(t('team_config_customRates_bonus_error'))
          .notRequired()
          .nullable()
          .transform((currentValue, originalValue) => {
            return originalValue === '' ? 0 : currentValue;
          }),
      })),
      otherwise: (schema) => schema.notRequired(),
    })
  }).required();
}

export default function Config() {
  const { t } = useTranslation();
  const { data } = useLoaderData();
  const navigate = useNavigate();
  const { submitTeamOnboarding } = useSubmitTeamOnboarding();

  const { customRates, accountingClosureMonth } = data;
  const customRatesLength = customRates ? customRates.length : 0;

  const methods = useForm({
    defaultValues: {
      country: data.country ?? '',
      accountingClosureMonth: accountingClosureMonth ? accountingClosureMonth.toString() : '',
      rateType: customRatesLength > 0 ? 'custom' : 'country',
      customRates: customRatesLength > 0 ? customRates : [
        { index: '', bonus: '' }
      ],
    },
    resolver: yupResolver(getValidationSchema(t))
  });

  const { control, watch, setFocus, formState: { isSubmitting, errors } } = methods;
  const { fields: customRatesFields} = useFieldArray({
    control,
    name: 'customRates',
  });

  const watchRateType = watch('rateType');

  useEffect(() => {
    if ('custom' === watchRateType) {
      setFocus('customRates.0.index');
    }
  }, [watchRateType, setFocus])

  const onSubmit = async (values) => {
    const customRates = 'custom' === values.rateType ? values.customRates : [];
    const country = getCountry(values.country);

    await submitTeamOnboarding(
      data.companyName,
      country.code,
      data.fleetSize,
      data.usage,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      parseInt(values.accountingClosureMonth),
      customRates.map((rate, index) => ({
        label: `Interne ${index + 1}`,
        index: rate.index,
        bonus: rate.bonus,
        currency: country.currency.code,
      })),
      data.invitations,
      false,
    );

    navigate("/join/team/invitations");
  };

  return (
    <MainContainer>
      <MainHeadingSection>
        <MainTitle>Configuration</MainTitle>
        <MainText>
          Nous avons besoin de quelques informations pour configurer l'application :
        </MainText>
      </MainHeadingSection>

      <Form methods={methods} onSubmit={onSubmit}>
        <div className="space-y-5">
          <div>
            <Label htmlFor="country">
              <Trans i18nKey="team_config_country_label" components={{ sup: <sup/> }}>
                Pays de l'entreprise <sup>*</sup>
              </Trans>
            </Label>

            <CountrySelect
              id="country"
              name="country"
            />
          </div>

          <div>
            <Label htmlFor="accountingClosureMonth">
              <Trans i18nKey="team_config_accountingClosureMonth_label" components={{ sup: <sup/> }}>
                Mois de clôture fiscale <sup>*</sup>
              </Trans>
            </Label>

            <MonthSelect
              id="accountingClosureMonth"
              name="accountingClosureMonth"
            />
          </div>

          <fieldset className="mt-6">
            <legend className="contents text-base font-medium text-gray-900">Barème pour le calcul des IK</legend>
            <p className="text-sm text-gray-500">
              Izika vous permet d'utiliser le barème officiel ou de configurer votre propre barème interne.<br/>
              <small>Note: vous pouvez modifier ces paramètres ultérieurement</small>.</p>
            <div className="mt-4 space-y-4">
              <Radio
                id="rateType-country"
                name="rateType"
                value="country"
              >
                Utiliser le barème officiel
              </Radio>

              <Radio
                id="rateType-custom"
                name="rateType"
                value="custom"
              >
                Utiliser un barème interne
              </Radio>

              {'custom' === watchRateType && (
                <div>
                  <ul>
                    {customRatesFields.map((field, index) => (
                      <li key={field.id} className="mb-4">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <span
                            className="inline-flex items-center rounded-l-md border border-r-0 border-brown-B500 bg-brown-B50 px-3 text-gray-500 sm:text-sm">
                            (d x
                          </span>
                          <Input
                            id={`customRates.${index}.index`}
                            name={`customRates.${index}.index`}
                            autoFocus
                            placeholder="indice"
                            variant="group"
                            showErrors={false}
                          />
                          <span
                            className="inline-flex items-center border border-r-0 border-l-0 border-brown-B500 bg-brown-B50 px-3 text-gray-500 sm:text-sm">
                            ) +
                          </span>
                          <Input
                            id={`customRates.${index}.bonus`}
                            name={`customRates.${index}.bonus`}
                            placeholder="bonus"
                            variant="group"
                            showErrors={false}
                            className="rounded-r-md"
                          />
                        </div>

                        {(errors.customRates?.[index]?.index || errors.customRates?.[index]?.bonus) && (
                          <ErrorContainer>
                            <Trans i18nKey="team_config_customRates_index_or_bonus_error">
                              Cette saisie n'est pas valide
                            </Trans>
                          </ErrorContainer>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <SubmitButton loading={isSubmitting} className="mt-6">
              <Trans i18nKey="team_company_submit">
                Continuer
              </Trans>
            </SubmitButton>
          </fieldset>
        </div>
      </Form>
    </MainContainer>
  );
}
